
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import ProductCombosForm from '@/components/productCombos/ProductCombosForm.vue';
import { Venue } from '@/interfaces/models/Venue';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { Article } from '@/interfaces/models/Article';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';

const venue = namespace('venue');

const api = new FoodcardApiService();

@Component({
  components: { VWrapper, ProductCombosForm },
})
export default class ProductCombos extends mixins(StackedForm, Notification) {
  @venue.State('active') public venue!: Venue;
  @venue.Action('update') public update!: any;

  public articles: Array<Partial<Article>> = [];

  public $refs!: {
    combosForm: InstanceType<typeof ProductCombosForm> & { getData: () => any; initData: () => any };
  };

  public async mounted() {
    if (!this.venue || !this.venue._id) return;
    this.$startLoading('productCombos');
    const { data } = await api.getArticleNames({ venue: this.venue._id });
    this.articles = data;
    this.$stopAllLoading();
  }

  public async save(): Promise<void> {
    this.$startLoading('venue.save');
    try {
      const data: any = { ...this.$refs.combosForm.getData() };
      data.id = this.venue._id;

      await this.update(data);
    } catch (e) {
      this.notifyError('notification.500');
    } finally {
      this.$stopLoading('venue.save');
    }
  }

  @Watch('venue')
  public async onVenueChange() {
    if (!this.venue || !this.venue._id) return;
    this.$startLoading('productCombos');
    const { data } = await api.getArticleNames({ venue: this.venue._id });
    this.articles = data;
    this.$stopAllLoading();
  }
}


import { Component, Prop } from 'vue-property-decorator';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import { Article } from '@/interfaces/models/Article';
import _ from 'lodash';

@Component({
  components: {
    VFormBuilder,
  },
})
export default class ProductCombosModal extends mixins(StackedForm) {
  @Prop({ type: Array }) public articles!: Array<Partial<Article>>;
  public show = false;
  public path: number[] = [];
  public isParent = false;

  get items() {
    return [
      {
        name: 'itemId',
        type: InputType.Autocomplete,
        label: '',
        items: this.articlesFormatted,
        rules: 'required',
      },
    ];
  }

  get articlesFormatted() {
    return this.articles.map((article: Partial<Article>) => {
      return {
        value: article._id,
        text: `${this.$options.filters!.localized(article.name)} ${
          article.number ? this.getFormattedNumber(article.number) : ''
        }`,
      };
    });
  }

  public getFormattedNumber(numberArticle: string) {
    return `(${numberArticle})`;
  }

  public async add() {
    if (!(await this.$refs.form.validate())) {
      return;
    }

    const { itemId } = _.cloneDeep(this.$refs.form.getData());
    this.isParent ? this.$emit('addColumn', itemId) : this.$emit('addArticle', { itemId, path: this.path });
    this.setShowModal(false, false, []);
  }

  public setShowModal(showModal: boolean = true, isParent: boolean, path: number[]) {
    this.isParent = isParent;
    this.path = path;
    this.show = showModal;
    if (!showModal) {
      this.$refs.form.reset();
      this.$refs.form.clearErrors();
      this.isParent = false;
      this.path = [];
    }
  }
}


import { Component, Prop, Watch } from 'vue-property-decorator';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { ProductCombo } from '@/interfaces/models/ProductCombo';
import { Article } from '@/interfaces/models/Article';
import ProductCombosModal from './ProductCombosModal.vue';
import _ from 'lodash';

@Component({
  components: {
    ProductCombosModal,
  },
})
export default class ProductCombosForm extends mixins(StackedForm) {
  @Prop({ type: Array }) public productCombo!: ProductCombo[];
  @Prop({ type: Array }) public articles!: Article[];
  @Prop({ type: String }) public venueId!: string;
  private combos: ProductCombo[] = [];

  public async mounted() {
    this.combos = _.cloneDeep(this.productCombo);
  }

  public addColumn(id: string) {
    this.combos.push({
      columns: [],
      result: id,
    });
  }

  public addArticle(data: { itemId: string; path: number[] }) {
    if (this.combos[data.path[0]].columns.length === data.path[1]) {
      this.combos[data.path[0]].columns.push([]);
    }
    this.combos[data.path[0]].columns[data.path[1]].push(data.itemId);
  }

  public openArticleModal(path: number[], isParent: boolean) {
    this.$refs.articleDialog.setShowModal(true, isParent, path);
  }

  public async deleteColumn(path: number[], skipConfirmation: boolean = false) {
    if (skipConfirmation || (await this.$confirm())) {
      this.combos[path[0]].columns.splice(path[1], 1);
    }
  }

  public async deleteArticle(path: number[]) {
    if (await this.$confirm()) {
      if (this.combos[path[0]].columns[path[1]].length === 1) {
        await this.deleteColumn([path[0], path[1]], true);
        return;
      }
      this.combos[path[0]].columns[path[1]].splice(path[2], 1);
    }
  }

  public deleteConmbo(combo: ProductCombo, combo_index: number) {
    this.combos = this.combos.filter((com: ProductCombo) => com.result !== combo.result);
  }

  public getData(): any {
    return {
      productCombos: this.combos,
    };
  }

  public getArticleName(id: string) {
    return this.articles.find((a: Partial<Article>) => a._id === id);
  }

  @Watch('productCombo')
  public async onproductComboChange() {
    this.combos = _.cloneDeep(this.productCombo);
  }
}
